import { backendEndpoint } from 'util/helpers';

const endpoint = `${backendEndpoint}/api/blog`;

export const getBlogSegmentsFull = () => (
    fetch(`${endpoint}/blog-segment-list-full`, {
        method: 'GET',
        mode: 'cors',
        credentials: 'include'
    })
);

export const getBlogSegmentsMinimum = () => (
    fetch(`${endpoint}/blog-segment-list-min`, {
        method: 'GET',
        mode: 'cors',
        credentials: 'include'
    })
);

export const getBlogSegmentBySegmentId = (segmentID) => (
    fetch(`${endpoint}/blog-segment-by-id`, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        body: JSON.stringify({
            segmentID
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
);

export const getBlogPostById = (collection, postID) => (
    fetch(`${endpoint}/blog-post-by-id`, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        body: JSON.stringify({
            collection,
            postData: {
                postID
            }
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
);

export const getBlogPostBtTimestamp = (collection, postTimestamp) => (
    fetch(`${endpoint}/blog-post-by-timestamp`, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        body: JSON.stringify({
            collection,
            postData: {
                postTimestamp
            }
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
);

export const modifyBlogSegment = (segmentID, segmentData) => (
    fetch(`${endpoint}/blog-segment-mod`, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        body: JSON.stringify({
            segmentID,
            segmentData
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
);

export const addBlogSegment = (segmentData) => (
    fetch(`${endpoint}/blog-segment-add`, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        body: JSON.stringify({
            segmentData
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
);

export const importBlogSegment = (segmentCollectionName, segmentData) => (
    fetch(`${endpoint}/blog-segment-import`, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        body: JSON.stringify({
            segmentCollectionName,
            segmentData: Buffer.from(segmentData, 'utf-8').toString('base64')
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
);

export const exportBlogSegment = (segmentCollectionName) => (
    fetch(`${endpoint}/blog-segment-export`, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        body: JSON.stringify({
            segmentCollectionName
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
);

export const modifyBlogPost = (collection, postID, postData) => (
    fetch(`${endpoint}/blog-post-mod`, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        body: JSON.stringify({
            collection,
            postID,
            postData
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
);

export const addBlogPost = (collection, postContent, navStyle) => (
    fetch(`${endpoint}/blog-post-add`, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        body: JSON.stringify({
            collection,
            postData: {
                postContent,
                navStyle
            }
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
);

export const deleteBlogPost = (collection, postID) => (
    fetch(`${endpoint}/blog-post-delete`, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        body: JSON.stringify({
            collection,
            postData: {
                postID
            }
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
);

export const getBlogPostTitles = (collection) => (
    fetch(`${endpoint}/blog-post-titles`, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        body: JSON.stringify({
            collection
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
);

export const getBlogPostLowestHighestTimestamps = (collection) => (
    fetch(`${endpoint}/blog-post-lowest-highest-timestamp`, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        body: JSON.stringify({
            collection
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
);

export const getBlogSegmentSearchResults = (collection, query) => (
    fetch(`${endpoint}/blog-post-search`, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        body: JSON.stringify({
            collection,
            query
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
);