import React, { useEffect, useState } from 'react';
import history from 'util/history';
import ReactGA from 'react-ga';
import { LazyRoute, AuthRoute, AdminProtectedRoute , ProtectedRoute} from 'util/route';
import { Router, Switch, Redirect } from 'react-router-dom';

ReactGA.initialize('UA-136308056-1');

var firstLoad = true;

if (firstLoad) {
    ReactGA.set({ page: window.location.pathname }); // Update the user's current page
    ReactGA.pageview(window.location.pathname); // Record a pageview for the given page
}

history.listen(location => {
    firstLoad = false;
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

//Main Pages
const Welcome = React.lazy(() => import('components/Welcome.jsx'));
const LogIn = React.lazy(() => import('components/User/Login.jsx'));
const SignUp = React.lazy(() => import('components/User/SignUp.jsx'));
const ContactUs = React.lazy(() => import('components/Contact/ContactUs.jsx'));
const Dashboard = React.lazy(() => import('components/User/Dashboard.jsx'));
const MediaModalDemo = React.lazy(() => import('components/Demos/MediaModalDemo.jsx'));
const CartButtonDemo = React.lazy(() => import('components/Demos/CartButtonDemo.jsx'));
const RatingsDemo = React.lazy(() => import('components/Demos/RatingsDemo.jsx'));
const InstagramPosts = React.lazy(() => import('components/InstagramPosts'));

//Project Pages
const ProjectsMenu = React.lazy(() => import('components/Projects/ProjectsMenu.jsx'));

//Download Pages
const AlienBrowser = React.lazy(() => import('components/Downloads/AlienBrowser.jsx'));
const AlienTimer = React.lazy(() => import('components/Downloads/AlienTimer.jsx'));
const HtmlEditor = React.lazy(() => import('components/Downloads/HtmlEditor.jsx'));
const MobileSync = React.lazy(() => import('components/Downloads/MobileSync.jsx'));
const JAAPU = React.lazy(() => import('components/Downloads/JAAProductionsUpdater.jsx'));
const JAAPUMissing = React.lazy(() => import('components/Downloads/JAAProductionsUpdaterMissing.jsx'));

//Music Pages
const ImprovRecordings = React.lazy(() => import('components/Music/ImprovRecordings.jsx'));
const CompMenuPage = React.lazy(() => import('components/Music/CompositionsMenu.jsx'));
const CompPage = React.lazy(() => import('components/Music/CompositionsPage.jsx'));

//Blog Pages
const BlogSegment = React.lazy(() => import('components/Blog/BlogSegment.jsx'));
const BlogSearch = React.lazy(() => import('components/Blog/BlogSearch.jsx'));

//Video Pages
const recordVideos = React.lazy(() => import('components/Videos/RecordVideos.jsx'));

//ETC Pages
const Page404 = React.lazy(() => import('components/Helpers/404.jsx'));

const App = ({ connected }) => {
  const [isConnected, setIsConnected] = useState(connected);

  useEffect(() => {
    if (isConnected !== connected) window.location.reload();
    setIsConnected(connected);
  }, [connected, isConnected]);

  return (
    <Router history={history}>
      <Switch>
        <LazyRoute exact path="/" component={Welcome} />
        <AuthRoute path="/login" component={LogIn} />
        <AdminProtectedRoute path="/signup" component={SignUp} />
        <LazyRoute path="/contact" exact component={ContactUs} />
        <Redirect from="/contact-us" to="/contact" />
        <ProtectedRoute path="/dashboard" exact component={Dashboard} />
        <LazyRoute exact path="/demos/media-modal" component={MediaModalDemo} />
        <LazyRoute exact path="/demos/cart-button" component={CartButtonDemo} />
        <LazyRoute exact path="/demos/ratings" component={RatingsDemo} />
        <LazyRoute exact path="/ig-posts" component={InstagramPosts} />

        {/*Project Page Routes*/}
        <LazyRoute path="/projects" exact component={ProjectsMenu} />
  
        {/*Download Page Routes*/}
        <LazyRoute path="/downloads/alienbrowser" exact component={AlienBrowser} />
        <LazyRoute path="/downloads/alientimer" exact component={AlienTimer} />
        <LazyRoute path="/downloads/html-editor" exact component={HtmlEditor} />
        <LazyRoute path="/downloads/mobile-sync" exact component={MobileSync} />
        <LazyRoute path="/downloads/jaa-productions-updater" exact component={JAAPU} />
        <LazyRoute path="/downloads/jaa-productions-updater-missing" exact component={JAAPUMissing} />
  
        {/*Music Page Routes*/}
        <LazyRoute path="/music/compositions" exact component={CompMenuPage} />
        <LazyRoute path="/music/compositions/comp-page" component={CompPage} />
        <LazyRoute path="/music/improv-recordings" exact component={ImprovRecordings} />
  
        {/*Blog Segment Pages*/}
        <LazyRoute path="/blogs/all" component={BlogSegment} />
        <Redirect from="/blogs/segment/all" to='/blogs/all' />
        <LazyRoute path="/blogs/segment" component={BlogSegment} />
        <LazyRoute path="/blogs/search" exact component={BlogSearch} />
        <Redirect from="/blogs/college-life" to={`/blogs/segment/college-life${(history.location.search === undefined) ? '' : history.location.search}`} />
        <Redirect from="/blogs/the-journal" to={`/blogs/segment/the-journal${(history.location.search === undefined) ? '' : history.location.search}`} />
  
        {/*Video Pages*/}
        <LazyRoute path="/videos/record-vids" exact component={recordVideos} />
  
        {/* ETC Pages */}
        <LazyRoute path="/404" component={Page404} />
        <Redirect to="/404" />
      </Switch>
    </Router>
  );
}

export default App;