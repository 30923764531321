import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import history from 'util/history';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout, subscribeToSessionValidation } from 'actions/session';
import { toggleEditMarqueeText, setMarqueeText } from 'actions/site_info';
import { initializeHeader, toggleMobileHeader } from 'assets/Helpers/js/headerFunctions';
import AdminSettingsFAB from 'components/Helpers/AdminSettingsFAB.jsx';
import { Menu as MenuIcon, ExitToApp as LogOutIcon, SupervisorAccount as ConsoleIcon } from '@material-ui/icons';
import Marquee from 'components/Helpers/Marquee.jsx';
import NewYearsFireworks from 'components/Helpers/NewYearsFireworks.jsx';
import mainHeaderImg from 'assets/Helpers/images/jaa2.png';
import 'assets/Helpers/css/headerStyle.css';

const motto = "";
// const motto = "It's Time for a Revolution!";
const websiteTitle = "J.A.A. Productions";

const NormalHeader = props => {
    const blogSegments = [];
    var hasReducedBlogSegmentsList = false;

    if (props.blogSegments && props.blogSegments.length > 3) {
        blogSegments.push(...props.blogSegments.slice(0, 3));
        blogSegments.push({'segment_id': 'all', 'segment_name': 'More...'});
        hasReducedBlogSegmentsList = true;
    }
    else {
        blogSegments.push(...props.blogSegments);
    }

    if (props.isAdmin && !hasReducedBlogSegmentsList) {
        blogSegments.push({'segment_id': 'all', 'segment_name': 'More...'});
    }

    const menuItems = (
        <div>
            <Link className="menu-item" to="/">Home</Link>
            <Link className="menu-item" to="/projects">Projects</Link>
            {/*<div className="menu-item sub-menu" style={{zIndex: 500}}>*/}
            {/*    <span>Downloads</span>*/}
            {/*    <div className="inner-menu">*/}
            {/*        <Link className="menu-item" to="/downloads/alienbrowser">AlienBrowser</Link><br />*/}
            {/*        <Link className="menu-item" to="/downloads/alientimer">AlienTimer</Link>*/}
            {/*        <Link className="menu-item" to="/downloads/html-editor">HTML Editor</Link>*/}
            {/*        <Link className="menu-item" to="/downloads/mobile-sync">Mobile Sync</Link>*/}
            {/*        <Link className="menu-item" to="/downloads/jaa-productions-updater">J.A.A. Productions Updater</Link>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/* <div className="menu-item" style={{zIndex: 499}}> */}
                {/* <span>Projects</span> */}
                {/* <div className="inner-menu"> */}
                    {/* <a className="menu-item" href="https://ttt.jaaproductions.com" target="new_tab">Online Tic-Tac-Toe</a> */}
                    {/* <a className="menu-item" href="/projects/des">DES Encryptor</a> */}
                    {/* <a className="menu-item" href="/projects/aes">AES Encryptor</a> */}
                    {/* <a className="menu-item" href={`http://${window.location.hostname}:3141`}>Remote RPi RGB LED Controller</a> */}
                    {/* <a className="menu-item" href="https://github.com/megaalpha200" target="new_tab">My Github Repos</a> */}
                {/* </div> */}
            {/* </div> */}
            <div className="menu-item sub-menu" style={{zIndex: 498}}>
                <span>Music</span>
                <div className="inner-menu">
                    <Link className="menu-item" to="/music/compositions">Compositions</Link>
                    <Link className="menu-item" to="/music/improv-recordings">Improvisation Recordings</Link>
                </div>
            </div>
            {
                (blogSegments.length > 0)
                ?
                    <div className="menu-item sub-menu" style={{zIndex: 497}}>
                        <span>Blogs</span>
                        <div className="inner-menu">
                            {blogSegments.map(segment => <Link className="menu-item" to={`/blogs/segment/${segment.segment_id}`}>{segment.segment_name}</Link>)}
                        </div>
                    </div>
                :
                    <span></span>
            }
            <div className="menu-item sub-menu" style={{zIndex: 496}}>
                <span>Videos</span>
                <div className="inner-menu">
                    <Link className="menu-item" to="/videos/record-vids">Vinyl Record Videos</Link>
                </div>
            </div>
            <Link className="menu-item" to="/ig-posts">Insta Posts</Link>
            <Link className="menu-item" to="/contact">Contact</Link>
            {/* {
                (props.isAdmin)
                ?
                    <>
                        <Link className="menu-item" to="/dashboard">Dashboard</Link>
                    </>
                :
                    <></>
            } */}
            {/* {
                (props.isAuthenticated)
                ?
                    <>
                        <Link to='' className="menu-item" onClick={() => props.logout()}>Log Out</Link>
                    </>
                :
                    <></>
            } */}
        </div>
    )

    return(
        <div>
            <Link to="/"><img id="header-img" src={mainHeaderImg} alt={`Website title: ${websiteTitle}`} /></Link>
            <p id="motto">{motto}</p>
            <nav>
                {/* Full Menu */}
                <div id="menu-full">
                    {menuItems}
                </div>
                {/* **** */}

                {/* Mobile Menu */}
                <div id="menu-mobile" hidden={true}>
                    {menuItems.props.children.map(element => (Array.isArray(element.props.children) && !element.props.className) ? element.props.children.map(subElement => <p>{subElement}</p>) : <p>{element}</p>)}
                </div>
                <br />
                <span id="page-title">{props.pageTitle}</span>
                <br />
                <MenuIcon id="menu-button" onClick={() => props.setIsMobileMenuOpen(toggleMobileHeader())} />
                {/* **** */}
            </nav>
        </div>
    );
}

const AltHeader = () => {
    return(
        <div>
            <Link to="/"><img id="alt-header-img" src={mainHeaderImg} alt={`Website title: ${websiteTitle}`} /></Link>
            <p id="alt-motto">{motto}</p>
            <br />
            <nav id="alt-nav">
                <Link id="back-button" to="/">{'<-- Back Home'}</Link>
            </nav>
        </div>
    );
}

const Header = props => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const { subscribeToSessionValidation } = props;

    useEffect(() => {
        subscribeToSessionValidation();
    }, [subscribeToSessionValidation]);

    useEffect(() => {
        initializeHeader(props.isHeaderDisappearing);
    }, [props.isHeaderDisappearing]);

    const getHeader = (headerType, pageTitle, isAuthenticated, isAdmin, isPseudoAdmin) => {
        var header;
        var sep;
    
        if (headerType === 'Normal') {
            // header = NormalHeader(pageTitle, isAuthenticated, props.logout, isAdmin);
            header = <NormalHeader
                        isMobileMenuOpen={isMobileMenuOpen}
                        setIsMobileMenuOpen={setIsMobileMenuOpen}
                        pageTitle={pageTitle}
                        blogSegments={props.blogSegments}
                        isAuthenticated={isAuthenticated}
                        logout={props.logout}
                        isAdmin={isAdmin || isPseudoAdmin}
                    />;
            sep = <p id="head-sep"></p>;
        }
        else if (headerType === 'Alt') {
            header = AltHeader();
            sep = <p id="head-sep-alt"></p>;
        }

        const preciseCurrentDate = new Date(Date.now());
        const relativeCurrentDate = new Date(preciseCurrentDate.getFullYear(), preciseCurrentDate.getMonth(), preciseCurrentDate.getDate());
        const isNewYearsDay = new Date(new Date().getFullYear(), 0, 1).getTime() === relativeCurrentDate.getTime();

        const permanentAdminFABActions = [];

        if (window.location.pathname !== '/dashboard') {
            permanentAdminFABActions.push({ name: 'Go to Dashboard', icon: <ConsoleIcon />, onClick: () => history.push('/dashboard') });
        }
        
        permanentAdminFABActions.push({ name: 'Log Out', icon: <LogOutIcon />, onClick: () => props.logout() });
    
        return (
            <header>
                <div id="header-container">
                    
                    {
                        (isNewYearsDay)
                        ?
                            <div>
                                <NewYearsFireworks />
                                <Marquee
                                    marqueeText={props.marquee_text_new_year}
                                    isAdmin={isAdmin}
                                />
                            </div>
                        :
                            <Marquee
                                marqueeText={(props.canEditMarqueeText) ? props.temp_marquee_text : props.marquee_text}
                                setMarqueeText={props.setMarqueeText}
                                isAdmin={(isAdmin || isPseudoAdmin) && window.location.pathname === '/dashboard'}
                                isEditMode={props.canEditMarqueeText}
                                toggleEditMode={props.toggleEditMarqueeText}
                            />
                    }
                    <br />
                    {header}
                </div>
                {sep}
                <div id="menu-transparent-film" hidden={true} onClick={() => setIsMobileMenuOpen(toggleMobileHeader())}></div>
                <AdminSettingsFAB
                    actions={
                        [
                            ...props.adminSettingsFABActionData.actions,
                            ...permanentAdminFABActions
                        ]
                    }
                    keepOpen={props.adminSettingsFABActionData.keepOpen}
                />
            </header>
        );
    }

    return getHeader(props.headerType, props.pageTitle, props.loggedIn, props.isAdmin, props.isPseudoAdmin);
}

Header.propTypes = {
    headerType: PropTypes.oneOf(['Normal', 'Alt']),
    pageTitle: PropTypes.string,
    isHeaderDisappearing: PropTypes.bool,
    adminSettingsFABActionData: PropTypes.shape({ actions: PropTypes.array, keepOpen: PropTypes.bool }),
}

Header.defaultProps = {
    headerType: 'Normal',
    pageTitle: 'Page Title',
    isHeaderDisappearing: false,
    adminSettingsFABActionData: { actions: [], keepOpen: false },
}

const mapStateToProps = ({ session: { userId, isAdmin, isPseudoAdmin }, site_info: { marquee_text, temp_marquee_text, marquee_text_new_year, canEditMarqueeText, blogSegments } }) => ({
    loggedIn: Boolean(userId),
    isAdmin: Boolean(userId) && Boolean(isAdmin),
    isPseudoAdmin: Boolean(userId) && Boolean(isPseudoAdmin),
    marquee_text,
    temp_marquee_text,
    marquee_text_new_year,
    canEditMarqueeText,
    blogSegments
});

const mapDispatchToProps = dispatch => ({
    subscribeToSessionValidation: () => dispatch(subscribeToSessionValidation()),
    logout: () => dispatch(logout()),
    setMarqueeText: marqueeText => dispatch(setMarqueeText(marqueeText)),
    toggleEditMarqueeText: () => dispatch (toggleEditMarqueeText())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Header);