import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { SocialIcon } from 'react-social-icons';
import { formatDate } from 'assets/Helpers/js/dateFunctions';
import { combinedQueryURL } from 'util/helpers';
import 'assets/Helpers/css/footerStyle.css';
import logo from 'assets/Helpers/images/jaa2.png';

const Footer = (props) => {
    return (
        <div id="footer-container">
            <div className="footer-item">
                <div className="footer-inner-item">
                    <div className="footer-section" style={{ flexGrow: 1, textAlign: 'center' }}>
                        <Link to={`/login?original_url=${combinedQueryURL()}`}><img className="footer-logo" src={logo} alt="J.A.A. Productions Logo" /></Link>
                        <h4 className="footer-heading">Last Update</h4>
                        <div className="footer-heading-underline"></div>
                        <br />
                        <p>
                            <strong>{(isNaN(props.last_update) ? props.last_update : formatDate(Number(props.last_update), true))}</strong>
                        </p>
                    </div>
                    <div className="footer-section" style={{ flexGrow: 2 }}>
                        <h4 className="footer-heading">Contact Info</h4>
                        <div className="footer-heading-underline"></div>
                        <br />
                        <p><u>Email:</u><br /><span><a href="mailto:contactus@jaaproductions.com" style={{ color: '#ffffff' }}>CONTACTUS@JAAPRODUCTIONS.COM</a></span></p>
                        <p>
                            <SocialIcon id="social-icons" bgColor="#ffffff" style={{ height: 40, width: 40 }} url="https://www.linkedin.com/in/jalvarado7707/" target="new_tab" />
                            <SocialIcon id="social-icons" bgColor="#ffffff" style={{ height: 40, width: 40 }} url="https://www.instagram.com/megaalpha200/" target="new_tab" />
                            {/* <SocialIcon id="social-icons" bgColor="#ffffff" style={{ height: 40, width: 40 }} url="https://www.twitter.com/megaalpha200/" target="new_tab" /> */}
                        </p>
                    </div>
                    <div className="footer-section" style={{ flexGrow: 2, textAlign: 'center' }}>
                        <h4 className="footer-heading">Location</h4>
                        <div className="footer-heading-underline"></div>
                        <br />
                        <iframe title="J.A.A. Productions Location Map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d442954.7788648995!2d-94.40992259191249!3d29.847421256894073!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x863ec69cb4817533%3A0x6bac778606c78123!2sJefferson%20County%2C%20TX!5e0!3m2!1sen!2sus!4v1599362609409!5m2!1sen!2sus" frameBorder="0" style={{ border: '0', width: '25rem', height: '15rem', maxWidth: '100%' }} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
                    </div>
                </div>
            </div>
            <div className="footer-item copyright-container" style={{ textAlign: 'center' }}>
                <br/>
                <p id="copyright">
                    &copy; J.A.A. Productions 2012 - {new Date().getFullYear()}
                    <br />
                    All Rights Reserved
                    {/*<br />*/}
                    {/*Powered by the <a href="https://hexipidemo.tk" target="new_tab" style={{ color: 'white', textDecoration: 'none', fontStyle: 'italic' }}>#HexiPi Template</a>*/}
                </p>
            </div>
        </div>
    );
};

const mapStateToProps = ({ site_info: { last_update } }) => ({
    last_update
});

export default connect(
    mapStateToProps
)(Footer);