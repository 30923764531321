import React, { useEffect, useState } from 'react';
import { Fireworks } from 'fireworks/lib/react'

const NewYearsFireworks = () => {
    const [fireworksCount, setFireworksCount] = useState(3);

    useEffect(() => {
        if (window.innerWidth <= 960) {
            setFireworksCount(1);
        }
        else {
            setFireworksCount(3);
        }
    }, []);

    const fxProps = {
        count: fireworksCount,
        interval: 1500,
        colors: ['#cc3333', '#4CAF50', '#81C784'],
        calc: (props, i) => ({
            ...props,
            x: (i + 1) * (window.innerWidth / 3) - (i + 1) * 100,
            y: 200 + Math.random() * 100 - 50 + (i === 2 ? -80 : 0)
        })
    } 

    return <Fireworks {...fxProps} />;
}

export default NewYearsFireworks;